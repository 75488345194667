(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bb.S === region.bA.S)
	{
		return 'on line ' + region.bb.S;
	}
	return 'on lines ' + region.bb.S + ' through ' + region.bA.S;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dU,
		impl.fk,
		impl.fa,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		a_: func(record.a_),
		bf: record.bf,
		a7: record.a7
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.a_;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bf;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.a7) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dU,
		impl.fk,
		impl.fa,
		function(sendToApp, initialModel) {
			var view = impl.fn;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dU,
		impl.fk,
		impl.fa,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.a8 && impl.a8(sendToApp)
			var view = impl.fn;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bp);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cq) && (_VirtualDom_doc.title = title = doc.cq);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.ew;
	var onUrlRequest = impl.ex;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		a8: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.b1 === next.b1
							&& curr.bJ === next.bJ
							&& curr.bZ.a === next.bZ.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dU: function(flags)
		{
			return A3(impl.dU, flags, _Browser_getUrl(), key);
		},
		fn: impl.fn,
		fk: impl.fk,
		fa: impl.fa
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dN: 'hidden', cO: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dN: 'mozHidden', cO: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dN: 'msHidden', cO: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dN: 'webkitHidden', cO: 'webkitvisibilitychange' }
		: { dN: 'hidden', cO: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		ca: _Browser_getScene(),
		fo: {
			cu: _Browser_window.pageXOffset,
			cv: _Browser_window.pageYOffset,
			fp: _Browser_doc.documentElement.clientWidth,
			dL: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		fp: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		dL: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			ca: {
				fp: node.scrollWidth,
				dL: node.scrollHeight
			},
			fo: {
				cu: node.scrollLeft,
				cv: node.scrollTop,
				fp: node.clientWidth,
				dL: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			ca: _Browser_getScene(),
			fo: {
				cu: x,
				cv: y,
				fp: _Browser_doc.documentElement.clientWidth,
				dL: _Browser_doc.documentElement.clientHeight
			},
			dh: {
				cu: x + rect.left,
				cv: y + rect.top,
				fp: rect.width,
				dL: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dj.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dj.b, xhr)); });
		$elm$core$Maybe$isJust(request.cr) && _Http_track(router, xhr, request.cr.a);

		try {
			xhr.open(request.ek, request.ct, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.ct));
		}

		_Http_configureRequest(xhr, request);

		request.bp.a && xhr.setRequestHeader('Content-Type', request.bp.a);
		xhr.send(request.bp.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.dK; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.fg.a || 0;
	xhr.responseType = request.dj.d;
	xhr.withCredentials = request.cA;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		ct: xhr.responseURL,
		ci: xhr.status,
		e6: xhr.statusText,
		dK: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			e_: event.loaded,
			cf: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			eO: event.loaded,
			cf: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $author$project$Translation$KeyWithDefault = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Blurbs$blurbs = {
	aM: A2($author$project$Translation$KeyWithDefault, 711122, 'Cancel'),
	aN: A2($author$project$Translation$KeyWithDefault, 728064, 'Click ‘Submit’ to submit your answers and see your test results. Click ‘Cancel’ to stay in this section.'),
	aO: A2($author$project$Translation$KeyWithDefault, 728062, 'You have now finished ‘{{currentSectionTrans}}’. Are you sure you want to continue to the next section?'),
	aP: A2($author$project$Translation$KeyWithDefault, 728063, 'You have completed the test!'),
	aQ: A2($author$project$Translation$KeyWithDefault, 717323, 'Congratulations!'),
	aS: A2($author$project$Translation$KeyWithDefault, 728061, 'Continue to next section'),
	aT: A2($author$project$Translation$KeyWithDefault, 125870, 'Continue to next level'),
	aW: A2($author$project$Translation$KeyWithDefault, 728065, 'You have not passed the Level {{levelNumber}} Test, with an overall score of {{overallScore}}%.'),
	a2: A2($author$project$Translation$KeyWithDefault, 125853, 'Next'),
	eC: A2($author$project$Translation$KeyWithDefault, 728068, 'Overall score'),
	a4: A2($author$project$Translation$KeyWithDefault, 728066, 'You have passed the Level {{levelNumber}} Test, with an overall score of {{overallScore}}%.'),
	a5: A2($author$project$Translation$KeyWithDefault, 728060, 'Please check your answers carefully, as you may not return to this section once you move on.'),
	a6: A2($author$project$Translation$KeyWithDefault, 714185, 'Previous'),
	eM: A2($author$project$Translation$KeyWithDefault, 125869, 'Questions'),
	eU: A2($author$project$Translation$KeyWithDefault, 126230, 'Retake the test'),
	a9: A2($author$project$Translation$KeyWithDefault, 728067, 'View E-book'),
	ba: A2($author$project$Translation$KeyWithDefault, 718942, 'Sorry!'),
	bc: A2($author$project$Translation$KeyWithDefault, 714198, 'Start now'),
	bd: A2($author$project$Translation$KeyWithDefault, 728058, 'Start the Test'),
	bg: A2($author$project$Translation$KeyWithDefault, 127632, 'Submit'),
	bi: A2($author$project$Translation$KeyWithDefault, 125846, 'The test contains four sections: Grammar, Listening, Vocabulary and Reading. Once you leave each section, you will not be able to return to the previous one.'),
	bj: A2($author$project$Translation$KeyWithDefault, 125845, 'The test will determine how well you\'ve understood the language in this level. The test takes about 40 minutes.'),
	bk: A2($author$project$Translation$KeyWithDefault, 151789, 'View certificate'),
	bl: A2($author$project$Translation$KeyWithDefault, 728059, 'Welcome to the {{levelName}} Test')
};
var $author$project$Blurbs$allBlurbs = _List_fromArray(
	[$author$project$Blurbs$blurbs.bl, $author$project$Blurbs$blurbs.bj, $author$project$Blurbs$blurbs.bi, $author$project$Blurbs$blurbs.bd, $author$project$Blurbs$blurbs.eM, $author$project$Blurbs$blurbs.bc, $author$project$Blurbs$blurbs.a2, $author$project$Blurbs$blurbs.a6, $author$project$Blurbs$blurbs.aM, $author$project$Blurbs$blurbs.aS, $author$project$Blurbs$blurbs.a5, $author$project$Blurbs$blurbs.aO, $author$project$Blurbs$blurbs.aP, $author$project$Blurbs$blurbs.aN, $author$project$Blurbs$blurbs.bg, $author$project$Blurbs$blurbs.a9, $author$project$Blurbs$blurbs.eU, $author$project$Blurbs$blurbs.ba, $author$project$Blurbs$blurbs.aQ, $author$project$Blurbs$blurbs.aT, $author$project$Blurbs$blurbs.bk, $author$project$Blurbs$blurbs.aW, $author$project$Blurbs$blurbs.a4, $author$project$Blurbs$blurbs.eC]);
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{h: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bF: fragment, bJ: host, bW: path, bZ: port_, b1: protocol, b2: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Scaffold$InitialisationFailed = {$: 2};
var $author$project$Scaffold$ReceiveViewport = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Scaffold$Uninitialised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$bubbleError = _Platform_outgoingPort(
	'bubbleError',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'display',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.a_)),
											_Utils_Tuple2(
											'title',
											$elm$json$Json$Encode$string($.cq))
										]));
							},
							$);
					}($.aj)),
					_Utils_Tuple2(
					'log',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'context',
											$elm$json$Json$Encode$string($.cW)),
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.a_))
										]));
							},
							$);
					}($.au))
				]));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Messages$LogMessage = F2(
	function (context, message) {
		return {cW: context, a_: message};
	});
var $author$project$Messages$MessageDisplay = F2(
	function (title, message) {
		return {a_: message, cq: title};
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Messages$decodingErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			aj: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			au: $elm$core$Maybe$Just(
				A2(
					$author$project$Messages$LogMessage,
					context,
					$elm$json$Json$Decode$errorToString(err)))
		};
	});
var $author$project$ScaffoldData$Flags = function (student) {
	return function (lang) {
		return function (config) {
			return function (url) {
				return function (fullUrl) {
					return function (urlParams) {
						return function (cachedBlurbs) {
							return function (externalBlurbs) {
								return function (externalLogo) {
									return function (isMarketCN) {
										return {Q: cachedBlurbs, I: config, dp: externalBlurbs, ak: externalLogo, an: fullUrl, ap: isMarketCN, C: lang, X: student, ct: url, aK: urlParams};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$JsonUtils$apply = F2(
	function (da, fAtoB) {
		return A3(
			$elm$json$Json$Decode$map2,
			F2(
				function (f, a) {
					return f(a);
				}),
			fAtoB,
			da);
	});
var $author$project$ScaffoldData$CachedBlurb = F3(
	function (loadedAt, maxAge, value) {
		return {eb: loadedAt, eh: maxAge, fm: value};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$JsonUtils$withDefault = F2(
	function (def, dec) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(def),
			$elm$json$Json$Decode$maybe(dec));
	});
var $author$project$ScaffoldData$cachedBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$author$project$JsonUtils$withDefault,
				$elm$time$Time$millisToPosix(0),
				A2(
					$elm$json$Json$Decode$field,
					'loadedAtPosix',
					A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int))),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$CachedBlurb))));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$ScaffoldData$blurbCacheDecoder = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$cachedBlurbDecoder));
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$ScaffoldData$externalBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultVal', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurbId', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$Translation$KeyWithDefault)));
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$ScaffoldData$externalBlurbsDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$values,
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$externalBlurbDecoder));
var $author$project$ScaffoldData$GeneralAppConfig = F6(
	function (languages, countries, genders, departments, seniorities, paths) {
		return {ag: countries, ai: departments, ao: genders, at: languages, eD: paths, aF: seniorities};
	});
var $author$project$ScaffoldData$EfConstant = F3(
	function (value, blurb, defaultLabel) {
		return {cG: blurb, c9: defaultLabel, fm: value};
	});
var $author$project$ScaffoldData$efConstantDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultLabel', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurb', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$EfConstant))));
var $author$project$ScaffoldData$Language = F2(
	function (value, label) {
		return {d0: label, fm: value};
	});
var $author$project$ScaffoldData$languageDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Language)));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Paths$Paths = function (currentCourse) {
	return function (changeCourse) {
		return function (grammarLab) {
			return function (appsAndTools) {
				return function (privateClass) {
					return function (currentBookings) {
						return function (groupClass) {
							return function (helpCenter) {
								return function (onboarding) {
									return function (accountSettings) {
										return function (progressAndGoals) {
											return function (efset30) {
												return function (efset30Retake) {
													return function (levelTest) {
														return function (home) {
															return {cx: accountSettings, cD: appsAndTools, cP: changeCourse, c1: currentBookings, c2: currentCourse, dd: efset30, de: efset30Retake, dD: grammarLab, dF: groupClass, dM: helpCenter, dO: home, d9: levelTest, ey: onboarding, eH: privateClass, eL: progressAndGoals};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Paths$PathObject = F3(
	function (url, external, blank) {
		return {cF: blank, $7: external, ct: url};
	});
var $author$project$ScaffoldData$pathObjectDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'blank', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'external', $elm$json$Json$Decode$bool),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$Paths$PathObject))));
var $author$project$ScaffoldData$pathsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'home', $author$project$ScaffoldData$pathObjectDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'levelTest', $author$project$ScaffoldData$pathObjectDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'efset30Retake', $author$project$ScaffoldData$pathObjectDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'efset30', $author$project$ScaffoldData$pathObjectDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'progressAndGoals', $author$project$ScaffoldData$pathObjectDecoder),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'accountSettings', $author$project$ScaffoldData$pathObjectDecoder),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'onboarding', $author$project$ScaffoldData$pathObjectDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'helpCenter', $author$project$ScaffoldData$pathObjectDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'groupClass', $author$project$ScaffoldData$pathObjectDecoder),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'currentBookings', $author$project$ScaffoldData$pathObjectDecoder),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'privateClass', $author$project$ScaffoldData$pathObjectDecoder),
											A2(
												$author$project$JsonUtils$apply,
												A2($elm$json$Json$Decode$field, 'appsAndTools', $author$project$ScaffoldData$pathObjectDecoder),
												A2(
													$author$project$JsonUtils$apply,
													A2($elm$json$Json$Decode$field, 'grammarLab', $author$project$ScaffoldData$pathObjectDecoder),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'changeCourse', $author$project$ScaffoldData$pathObjectDecoder),
														A2(
															$author$project$JsonUtils$apply,
															A2($elm$json$Json$Decode$field, 'currentCourse', $author$project$ScaffoldData$pathObjectDecoder),
															$elm$json$Json$Decode$succeed($author$project$Paths$Paths))))))))))))))));
var $author$project$ScaffoldData$generalAppConfigDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'paths', $author$project$ScaffoldData$pathsDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'seniorities',
			$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'departments',
				$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2(
					$elm$json$Json$Decode$field,
					'genders',
					$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'countries',
						$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						A2(
							$elm$json$Json$Decode$field,
							'languages',
							$elm$json$Json$Decode$list($author$project$ScaffoldData$languageDecoder)),
						$elm$json$Json$Decode$succeed($author$project$ScaffoldData$GeneralAppConfig)))))));
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$ScaffoldData$listToTupleDecoder = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (things) {
			if (things.b && things.b.b) {
				var a = things.a;
				var _v1 = things.b;
				var b = _v1.a;
				return $elm$json$Json$Decode$succeed(
					_Utils_Tuple2(a, b));
			} else {
				return $elm$json$Json$Decode$fail('Expected a list of things with at least two values to create a tuple from');
			}
		},
		$elm$json$Json$Decode$list(decoder));
};
var $author$project$ApiTypes$Student$Index$Student = function (firstName) {
	return function (lastName) {
		return function (country) {
			return function (gender) {
				return function (emailAddress) {
					return function (phoneNumber) {
						return function (emailLanguage) {
							return function (seniority) {
								return function (department) {
									return function (bookingConfirmationEmail) {
										return function (id) {
											return function (userId) {
												return function (companyId) {
													return function (grantsAndCredits) {
														return function (reportType) {
															return function (courseStart) {
																return function (courseExpiry) {
																	return function (studyTargets) {
																		return function (timezone) {
																			return function (clientTimezone) {
																				return {bq: bookingConfirmationEmail, cR: clientTimezone, cT: companyId, bu: country, cZ: courseExpiry, c_: courseStart, bx: department, by: emailAddress, bz: emailLanguage, bD: firstName, bG: gender, dE: grantsAndCredits, bK: id, bQ: lastName, bX: phoneNumber, b4: reportType, cd: seniority, e8: studyTargets, cp: timezone, fl: userId};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures = function (faceToFaceStudy) {
	return function (selfStudy) {
		return function (privateLesson) {
			return function (groupDiscussion) {
				return function (writingClass) {
					return function (personalCoaching) {
						return function (spinCourse) {
							return function (unitReview) {
								return function (efsetSpeaking) {
									return function (efset50) {
										return function (liveChat) {
											return function (retakePlacementTest) {
												return function (hultEfPro) {
													return function (groupScheduledLesson) {
														return function (privateScheduledLesson) {
															return function (catalyst) {
																return function (generalEnglish) {
																	return function (businessEnglish) {
																		return function (industryEnglish) {
																			return function (travelEnglish) {
																				return function (german) {
																					return function (french) {
																						return function (spanish) {
																							return function (swedish) {
																								return function (portuguese) {
																									return function (dubaiAirports) {
																										return function (customRocheSAG) {
																											return function (customRwanda) {
																												return function (customMcDonalds) {
																													return function (mango) {
																														return {cJ: businessEnglish, cM: catalyst, c4: customMcDonalds, c5: customRocheSAG, c6: customRwanda, dc: dubaiAirports, df: efset50, dg: efsetSpeaking, dr: faceToFaceStudy, dx: french, dy: generalEnglish, dA: german, dG: groupDiscussion, dI: groupScheduledLesson, dP: hultEfPro, dS: industryEnglish, ea: liveChat, ef: mango, eE: personalCoaching, eF: portuguese, eI: privateLesson, eK: privateScheduledLesson, eV: retakePlacementTest, eZ: selfStudy, e2: spanish, e3: spinCourse, fb: swedish, fh: travelEnglish, fj: unitReview, fr: writingClass};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry = F3(
	function (expired, expirationDate, quota) {
		return {dl: expirationDate, dm: expired, eN: quota};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$ApiTypes$GrantsAndCredits$QuotaLimited = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited = {$: 0};
var $author$project$ApiTypes$GrantsAndCredits$Limited = F2(
	function (amount, remaining) {
		return {cB: amount, eQ: remaining};
	});
var $author$project$Student$limitedDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'remaining', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$float),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$Limited)));
var $author$project$Student$quotaDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (s) {
				return (s === 'unlimited') ? $elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited) : $elm$json$Json$Decode$fail('unexpected string');
			},
			$elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$GrantsAndCredits$QuotaLimited, $author$project$Student$limitedDecoder)
		]));
var $author$project$Student$grantsAndCreditsEntryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'quota', $author$project$Student$quotaDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'expirationDate',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'expired',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool)),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry))));
var $author$project$Student$grantsAndCreditsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'mango', $author$project$Student$grantsAndCreditsEntryDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'customMcDonalds', $author$project$Student$grantsAndCreditsEntryDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'customRwanda', $author$project$Student$grantsAndCreditsEntryDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'customRocheSAG', $author$project$Student$grantsAndCreditsEntryDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'dubaiAirports', $author$project$Student$grantsAndCreditsEntryDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'portuguese', $author$project$Student$grantsAndCreditsEntryDecoder)),
						A2(
							$author$project$JsonUtils$apply,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'swedish', $author$project$Student$grantsAndCreditsEntryDecoder)),
							A2(
								$author$project$JsonUtils$apply,
								$elm$json$Json$Decode$maybe(
									A2($elm$json$Json$Decode$field, 'spanish', $author$project$Student$grantsAndCreditsEntryDecoder)),
								A2(
									$author$project$JsonUtils$apply,
									$elm$json$Json$Decode$maybe(
										A2($elm$json$Json$Decode$field, 'french', $author$project$Student$grantsAndCreditsEntryDecoder)),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'german', $author$project$Student$grantsAndCreditsEntryDecoder)),
										A2(
											$author$project$JsonUtils$apply,
											$elm$json$Json$Decode$maybe(
												A2($elm$json$Json$Decode$field, 'travelEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'industryEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'businessEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
													A2(
														$author$project$JsonUtils$apply,
														$elm$json$Json$Decode$maybe(
															A2($elm$json$Json$Decode$field, 'generalEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'catalyst', $author$project$Student$grantsAndCreditsEntryDecoder)),
															A2(
																$author$project$JsonUtils$apply,
																$elm$json$Json$Decode$maybe(
																	A2($elm$json$Json$Decode$field, 'privateScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																A2(
																	$author$project$JsonUtils$apply,
																	$elm$json$Json$Decode$maybe(
																		A2($elm$json$Json$Decode$field, 'groupScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																	A2(
																		$author$project$JsonUtils$apply,
																		$elm$json$Json$Decode$maybe(
																			A2($elm$json$Json$Decode$field, 'hultEfPro', $author$project$Student$grantsAndCreditsEntryDecoder)),
																		A2(
																			$author$project$JsonUtils$apply,
																			$elm$json$Json$Decode$maybe(
																				A2($elm$json$Json$Decode$field, 'retakePlacementTest', $author$project$Student$grantsAndCreditsEntryDecoder)),
																			A2(
																				$author$project$JsonUtils$apply,
																				$elm$json$Json$Decode$maybe(
																					A2($elm$json$Json$Decode$field, 'liveChat', $author$project$Student$grantsAndCreditsEntryDecoder)),
																				A2(
																					$author$project$JsonUtils$apply,
																					$elm$json$Json$Decode$maybe(
																						A2($elm$json$Json$Decode$field, 'efset50', $author$project$Student$grantsAndCreditsEntryDecoder)),
																					A2(
																						$author$project$JsonUtils$apply,
																						$elm$json$Json$Decode$maybe(
																							A2($elm$json$Json$Decode$field, 'efsetSpeaking', $author$project$Student$grantsAndCreditsEntryDecoder)),
																						A2(
																							$author$project$JsonUtils$apply,
																							$elm$json$Json$Decode$maybe(
																								A2($elm$json$Json$Decode$field, 'unitReview', $author$project$Student$grantsAndCreditsEntryDecoder)),
																							A2(
																								$author$project$JsonUtils$apply,
																								$elm$json$Json$Decode$maybe(
																									A2($elm$json$Json$Decode$field, 'spinCourse', $author$project$Student$grantsAndCreditsEntryDecoder)),
																								A2(
																									$author$project$JsonUtils$apply,
																									$elm$json$Json$Decode$maybe(
																										A2($elm$json$Json$Decode$field, 'personalCoaching', $author$project$Student$grantsAndCreditsEntryDecoder)),
																									A2(
																										$author$project$JsonUtils$apply,
																										$elm$json$Json$Decode$maybe(
																											A2($elm$json$Json$Decode$field, 'writingClass', $author$project$Student$grantsAndCreditsEntryDecoder)),
																										A2(
																											$author$project$JsonUtils$apply,
																											$elm$json$Json$Decode$maybe(
																												A2($elm$json$Json$Decode$field, 'groupDiscussion', $author$project$Student$grantsAndCreditsEntryDecoder)),
																											A2(
																												$author$project$JsonUtils$apply,
																												$elm$json$Json$Decode$maybe(
																													A2($elm$json$Json$Decode$field, 'privateLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																												A2(
																													$author$project$JsonUtils$apply,
																													$elm$json$Json$Decode$maybe(
																														A2($elm$json$Json$Decode$field, 'selfStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																													A2(
																														$author$project$JsonUtils$apply,
																														$elm$json$Json$Decode$maybe(
																															A2($elm$json$Json$Decode$field, 'faceToFaceStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																														$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures)))))))))))))))))))))))))))))));
var $author$project$ApiTypes$Student$Index$StudentTimezone = F2(
	function (key, displayName) {
		return {da: displayName, d$: key};
	});
var $author$project$Student$studentTimezoneDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'displayName', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudentTimezone)));
var $author$project$ApiTypes$Student$Index$StudyTargets = F8(
	function (generalEnglishUnits, industryEnglishUnits, businessEnglishUnits, privateLessons, groupLessons, mandatoryIndustryTopics, mandatoryBusinessTopics, initialTopic) {
		return {cK: businessEnglishUnits, dz: generalEnglishUnits, dH: groupLessons, dT: industryEnglishUnits, dV: initialTopic, ed: mandatoryBusinessTopics, ee: mandatoryIndustryTopics, eJ: privateLessons};
	});
var $author$project$ApiTypes$Student$Index$Course = $elm$core$Basics$identity;
var $author$project$ApiTypes$Student$Index$Topic = F2(
	function (id, course) {
		return {cY: course, bK: id};
	});
var $author$project$Student$topicDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'BE':
					return $elm$json$Json$Decode$succeed('BE');
				case 'IND':
					return $elm$json$Json$Decode$succeed('IND');
				case 'INDB2B':
					return $elm$json$Json$Decode$succeed('INDB2B');
				default:
					var course = str;
					return $elm$json$Json$Decode$fail('Unexpected topic course: ' + course);
			}
		},
		A2($elm$json$Json$Decode$field, 'course', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Topic)));
var $author$project$Student$studyTargetsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'initialTopic', $author$project$Student$topicDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'mandatoryBusinessTopics',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'mandatoryIndustryTopics',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'groupLessons', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'privateLessons', $elm$json$Json$Decode$float),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'businessEnglishUnits', $elm$json$Json$Decode$float),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'industryEnglishUnits', $elm$json$Json$Decode$float),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'generalEnglishUnits', $elm$json$Json$Decode$float),
								$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudyTargets)))))))));
var $author$project$Student$studentDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'clientTimezone', $author$project$Student$studentTimezoneDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'timezone', $author$project$Student$studentTimezoneDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'studyTargets', $author$project$Student$studyTargetsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'courseExpiry', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'courseStart', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'reportType', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'grantsAndCredits', $author$project$Student$grantsAndCreditsDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'companyId', $elm$json$Json$Decode$string),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'userId', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'bookingConfirmationEmail', $elm$json$Json$Decode$bool),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'department', $elm$json$Json$Decode$string)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'seniority', $elm$json$Json$Decode$string)),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'emailLanguage', $elm$json$Json$Decode$string),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'phoneNumber', $elm$json$Json$Decode$string)),
															A2(
																$author$project$JsonUtils$apply,
																A2($elm$json$Json$Decode$field, 'emailAddress', $elm$json$Json$Decode$string),
																A2(
																	$author$project$JsonUtils$apply,
																	A2($elm$json$Json$Decode$field, 'gender', $elm$json$Json$Decode$string),
																	A2(
																		$author$project$JsonUtils$apply,
																		A2($elm$json$Json$Decode$field, 'country', $elm$json$Json$Decode$string),
																		A2(
																			$author$project$JsonUtils$apply,
																			A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string),
																			A2(
																				$author$project$JsonUtils$apply,
																				A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string),
																				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Student)))))))))))))))))))));
var $author$project$ScaffoldData$flagsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'isMarketCN', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'externalLogo', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'externalBlurbs', $author$project$ScaffoldData$externalBlurbsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'cachedBlurbs', $author$project$ScaffoldData$blurbCacheDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'urlParams',
						$elm$json$Json$Decode$list(
							$author$project$ScaffoldData$listToTupleDecoder($elm$json$Json$Decode$string))),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'fullUrl', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'config', $author$project$ScaffoldData$generalAppConfigDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'lang', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'student', $author$project$Student$studentDecoder)),
										$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Flags)))))))))));
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$Scaffold$init = F2(
	function (resources, encodedFlags) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$ScaffoldData$flagsDecoder, encodedFlags);
		if (_v0.$ === 1) {
			var err = _v0.a;
			return _Utils_Tuple2(
				$author$project$Scaffold$InitialisationFailed,
				$author$project$Ports$bubbleError(
					A4(
						$author$project$Messages$decodingErrorMessage,
						'Error!',
						$elm$core$Maybe$Just('Unable to initialise the school'),
						'Decoding intial data',
						err)));
		} else {
			var flags = _v0.a;
			return _Utils_Tuple2(
				A2($author$project$Scaffold$Uninitialised, flags, $elm$core$Maybe$Nothing),
				A2(
					$elm$core$Task$perform,
					$author$project$Scaffold$ReceiveViewport(resources),
					$elm$browser$Browser$Dom$getViewport));
		}
	});
var $author$project$Scaffold$AppMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Scaffold$LanguageChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Scaffold$ModalMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Scaffold$StudentChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Scaffold$UrlChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Scaffold$WindowResize = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Ports$onLanguageChange = _Platform_incomingPort('onLanguageChange', $elm$json$Json$Decode$string);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {bY: pids, cl: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {bB: event, d$: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.bY,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.d$;
		var event = _v0.bB;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.cl);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$onStudentChange = _Platform_incomingPort('onStudentChange', $elm$json$Json$Decode$value);
var $author$project$Ports$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $author$project$Modal$InternalHide = {$: 3};
var $author$project$Modal$NoOp = {$: 4};
var $author$project$Modal$escDecoder = A2(
	$elm$json$Json$Decode$map,
	function (k) {
		return (k === 'Escape') ? $author$project$Modal$InternalHide : $author$project$Modal$NoOp;
	},
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$onKeyDown = A2($elm$browser$Browser$Events$on, 0, 'keydown');
var $author$project$Modal$subscriptions = function (model) {
	return model.y ? $elm$browser$Browser$Events$onKeyDown($author$project$Modal$escDecoder) : $elm$core$Platform$Sub$none;
};
var $author$project$Scaffold$subscriptions = F2(
	function (appSubs, model) {
		if (model.$ === 1) {
			var external = model.a;
			var appModel = model.b;
			var modal = model.c;
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$AppMsg,
						A2(appSubs, external, appModel)),
						$author$project$Ports$onLanguageChange($author$project$Scaffold$LanguageChanged),
						$author$project$Ports$onStudentChange($author$project$Scaffold$StudentChanged),
						$author$project$Ports$onUrlChange($author$project$Scaffold$UrlChanged),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$ModalMsg,
						$author$project$Modal$subscriptions(modal)),
						$elm$browser$Browser$Events$onResize($author$project$Scaffold$WindowResize)
					]));
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Scaffold$CompleteInitialisation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Scaffold$Initialised = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Scaffold$ReceivedTranslations = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Translation$translate = F2(
	function (_v0, textOrKey) {
		var dictionary = _v0;
		if (!textOrKey.$) {
			var txt = textOrKey.a;
			return txt;
		} else {
			var key = textOrKey.a;
			var _default = textOrKey.b;
			return A2(
				$elm$core$Maybe$withDefault,
				_default,
				A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					dictionary));
		}
	});
var $author$project$Translation$TranslationLookup = $elm$core$Basics$identity;
var $author$project$Translation$translationLookup = $elm$core$Basics$identity;
var $author$project$ScaffoldData$createTranslationFunc = F2(
	function (lang, blurbCache) {
		return $author$project$Translation$translate(
			$author$project$Translation$translationLookup(
				A2(
					$elm$core$Dict$map,
					F2(
						function (_v0, _v1) {
							var value = _v1.fm;
							return value;
						}),
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Dict$empty,
						A2($elm$core$Dict$get, lang, blurbCache)))));
	});
var $author$project$Scaffold$decodeStudentErrorBlurb = A2($author$project$Translation$KeyWithDefault, 728351, 'Unable to load student details');
var $author$project$Modal$defaultConfig = function (toggleEffect) {
	return {y: true, F: toggleEffect};
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$ScaffoldData$encodeCachedBlurb = function (blurb) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'loadedAtPosix',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(blurb.eb))),
				_Utils_Tuple2(
				'maxAge',
				$elm$json$Json$Encode$int(blurb.eh)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(blurb.fm))
			]));
};
var $author$project$ScaffoldData$encodeBlurbCache = A2(
	$elm$json$Json$Encode$dict,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $author$project$ScaffoldData$encodeCachedBlurb));
var $author$project$Scaffold$errorTitleBlurb = A2($author$project$Translation$KeyWithDefault, 728349, 'Error!');
var $author$project$Scaffold$extraBlurbs = _List_fromArray(
	[$author$project$Scaffold$errorTitleBlurb, $author$project$Scaffold$decodeStudentErrorBlurb]);
var $author$project$HttpUtils$logMessageFromHttpError = function (err) {
	switch (err.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var status = err.a;
			if (status === 401) {
				return $elm$core$Maybe$Just('401 received from the server. This should have been intercepted by the decorated XHR object');
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 4:
			var text = err.a;
			return $elm$core$Maybe$Just('Unable to decode the response from the api: ' + text);
		default:
			var url = err.a;
			return $elm$core$Maybe$Just('Malformed url: ' + url);
	}
};
var $author$project$Messages$httpErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			aj: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			au: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$LogMessage(context),
				$author$project$HttpUtils$logMessageFromHttpError(err))
		};
	});
var $author$project$Modal$init = function (config) {
	return {y: config.y, W: false, F: config.F, O: false};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$ServerPath$serverPathParts = _List_fromArray(
	['api', 'bff']);
var $author$project$ServerPath$serverPath = A2($elm$url$Url$Builder$absolute, $author$project$ServerPath$serverPathParts, _List_Nil);
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$HttpUtils$standardStringResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (resp) {
			switch (resp.$) {
				case 0:
					var url = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadUrl(url));
				case 1:
					return $elm$core$Result$Err($elm$http$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($elm$http$Http$NetworkError);
				case 3:
					var metadata = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadStatus(metadata.ci));
				default:
					var body = resp.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						var err = _v1.a;
						return $elm$core$Result$Err(
							$elm$http$Http$BadBody(
								$elm$json$Json$Decode$errorToString(err)));
					}
			}
		});
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{cA: false, bp: r.bp, dj: r.eS, dK: r.dK, ek: r.ek, fg: r.fg, cr: $elm$core$Maybe$Nothing, ct: r.ct});
};
var $author$project$ScaffoldApi$loadStudentTask = $elm$http$Http$task(
	{
		bp: $elm$http$Http$emptyBody,
		dK: _List_Nil,
		ek: 'get',
		eS: $author$project$HttpUtils$standardStringResolver($author$project$Student$studentDecoder),
		fg: $elm$core$Maybe$Nothing,
		ct: $author$project$ServerPath$serverPath + '/auth/student'
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$ScaffoldData$findHardCodedStrings = $elm$core$List$filterMap(
	function (b) {
		if (!b.$) {
			var txt = b.a;
			return $elm$core$Maybe$Just(txt);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$HttpUtils$whateverResolver = $elm$http$Http$stringResolver(
	function (resp) {
		switch (resp.$) {
			case 0:
				var url = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.ci));
			default:
				return $elm$core$Result$Ok(0);
		}
	});
var $author$project$ScaffoldApi$logHardCodedStrings = function (allBlurbs) {
	var hardcodedStrings = $author$project$ScaffoldData$findHardCodedStrings(allBlurbs);
	return $elm$core$List$isEmpty(hardcodedStrings) ? $elm$core$Task$succeed(0) : A2(
		$elm$core$Task$onError,
		function (_v0) {
			return $elm$core$Task$succeed(0);
		},
		$elm$http$Http$task(
			{
				bp: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'message',
								$elm$json$Json$Encode$string('The following hard coded strings must be replaced with blurbs:')),
								_Utils_Tuple2(
								'strings',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, hardcodedStrings))
							]))),
				dK: _List_Nil,
				ek: 'post',
				eS: $author$project$HttpUtils$whateverResolver,
				fg: $elm$core$Maybe$Nothing,
				ct: $author$project$ServerPath$serverPath + '/logging/error'
			}));
};
var $author$project$ScaffoldData$blurbExpired = F2(
	function (now, _v0) {
		var loadedAt = _v0.eb;
		var maxAge = _v0.eh;
		var nowMs = $elm$time$Time$posixToMillis(now);
		var loadedAtMs = $elm$time$Time$posixToMillis(loadedAt);
		var expiresAtMs = loadedAtMs + (maxAge * 1000);
		return _Utils_cmp(expiresAtMs, nowMs) < 0;
	});
var $author$project$ScaffoldData$blurbIsMissing = F4(
	function (now, blurbCache, lang, textOrKey) {
		if (!textOrKey.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var key = textOrKey.a;
			var _v1 = A2($elm$core$Dict$get, lang, blurbCache);
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Just(key);
			} else {
				var lookup = _v1.a;
				var _v2 = A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					lookup);
				if (_v2.$ === 1) {
					return $elm$core$Maybe$Just(key);
				} else {
					var cachedBlurb = _v2.a;
					return A2($author$project$ScaffoldData$blurbExpired, now, cachedBlurb) ? $elm$core$Maybe$Just(key) : $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $author$project$ScaffoldData$missingBlurbKeys = F4(
	function (requiredBlurbs, blurbCache, now, lang) {
		return A2(
			$elm$core$List$filterMap,
			A3($author$project$ScaffoldData$blurbIsMissing, now, blurbCache, lang),
			requiredBlurbs);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$core$Basics$round = _Basics_round;
var $author$project$DateUtils$floatToPosix = A2($elm$core$Basics$composeR, $elm$core$Basics$round, $elm$time$Time$millisToPosix);
var $author$project$ScaffoldData$translatedResourceToCachedBlurb = function (_v0) {
	var loadedAtPosix = _v0.ec;
	var maxAge = _v0.eh;
	var value = _v0.fm;
	return {
		eb: $author$project$DateUtils$floatToPosix(loadedAtPosix),
		eh: $elm$core$Basics$round(maxAge),
		fm: value
	};
};
var $author$project$ScaffoldData$mergeBlurbs = F3(
	function (lang, transResp, blurbCache) {
		return A3(
			$elm$core$Dict$insert,
			lang,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v0, dict) {
						var k = _v0.a;
						var v = _v0.b;
						return A3(
							$elm$core$Dict$insert,
							k,
							$author$project$ScaffoldData$translatedResourceToCachedBlurb(v),
							dict);
					}),
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2($elm$core$Dict$get, lang, blurbCache)),
				$elm$core$Dict$toList(transResp)),
			blurbCache);
	});
var $author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource = F4(
	function (maxAge, value, loadedAt, loadedAtPosix) {
		return {eb: loadedAt, ec: loadedAtPosix, eh: maxAge, fm: value};
	});
var $author$project$ScaffoldApi$translatedResourceDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'loadedAtPosix', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'loadedAt', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$float),
				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource)))));
var $author$project$ScaffoldApi$translationResponseDecoder = F2(
	function (lang, blurbCache) {
		return A2(
			$elm$json$Json$Decode$map,
			function (transResp) {
				return A3($author$project$ScaffoldData$mergeBlurbs, lang, transResp, blurbCache);
			},
			$elm$json$Json$Decode$dict($author$project$ScaffoldApi$translatedResourceDecoder));
	});
var $author$project$ScaffoldApi$loadTranslationsTask = F3(
	function (allBlurbs, lang, blurbCache) {
		return A2(
			$elm$core$Task$andThen,
			function (now) {
				return A2(
					$elm$core$Task$andThen,
					function (_v0) {
						var keys = A4($author$project$ScaffoldData$missingBlurbKeys, allBlurbs, blurbCache, now, lang);
						return $elm$core$List$isEmpty(keys) ? $elm$core$Task$succeed(blurbCache) : $elm$http$Http$task(
							{
								bp: $elm$http$Http$jsonBody(
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'lang',
												$elm$json$Json$Encode$string(lang)),
												_Utils_Tuple2(
												'keys',
												A2(
													$elm$json$Json$Encode$list,
													A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$json$Json$Encode$string),
													keys))
											]))),
								dK: _List_Nil,
								ek: 'post',
								eS: $author$project$HttpUtils$standardStringResolver(
									A2($author$project$ScaffoldApi$translationResponseDecoder, lang, blurbCache)),
								fg: $elm$core$Maybe$Nothing,
								ct: $author$project$ServerPath$serverPath + '/translate'
							});
					},
					$author$project$ScaffoldApi$logHardCodedStrings(allBlurbs));
			},
			$elm$time$Time$now);
	});
var $author$project$ScaffoldApi$loadInitialData = F3(
	function (allBlurbs, flags, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A2(
				$elm$core$Task$andThen,
				function (blurbCache) {
					var _v0 = flags.X;
					if (!_v0.$) {
						var student = _v0.a;
						return $elm$core$Task$succeed(
							{cH: blurbCache, X: student});
					} else {
						return A2(
							$elm$core$Task$map,
							function (s) {
								return {cH: blurbCache, X: s};
							},
							$author$project$ScaffoldApi$loadStudentTask);
					}
				},
				A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, flags.C, flags.Q)));
	});
var $author$project$ScaffoldApi$loadTranslations = F4(
	function (allBlurbs, lang, blurbCache, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, lang, blurbCache));
	});
var $author$project$ScaffoldData$ExtraLargeScreen = 3;
var $author$project$Scaffold$mediaUrlBase = 'https://et2.ef-cdn.com/';
var $author$project$Scaffold$mediaUrl = function (path) {
	return A2($elm$core$String$startsWith, 'http', path) ? path : _Utils_ap($author$project$Scaffold$mediaUrlBase, path);
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$ScaffoldData$LargeScreen = 2;
var $author$project$ScaffoldData$MediumScreen = 1;
var $author$project$ScaffoldData$SmallScreen = 0;
var $author$project$Scaffold$screenSizeFromWidth = function (width) {
	return (width < 768) ? 0 : ((width < 1024) ? 1 : ((width < 1264) ? 2 : 3));
};
var $author$project$Scaffold$makeExternalState = F5(
	function (flags, blurbCache, viewport, allBlurbs, student) {
		var screenSizeFromViewport = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.fo;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.fp;
				},
				A2($elm$core$Basics$composeR, $elm$core$Basics$round, $author$project$Scaffold$screenSizeFromWidth)));
		var screenSize = A2(
			$elm$core$Maybe$withDefault,
			3,
			A2($elm$core$Maybe$map, screenSizeFromViewport, viewport));
		return _Utils_Tuple2(
			{
				ac: allBlurbs,
				Q: blurbCache,
				ag: flags.I.ag,
				ai: flags.I.ai,
				ak: flags.ak,
				an: flags.an,
				ao: flags.I.ao,
				ap: flags.ap,
				C: flags.C,
				at: flags.I.at,
				aZ: $author$project$Scaffold$mediaUrl,
				eD: flags.I.eD,
				aE: screenSize,
				aF: flags.I.aF,
				X: student,
				_: A2($author$project$ScaffoldData$createTranslationFunc, flags.C, blurbCache),
				ct: flags.ct,
				aK: $elm$core$Dict$fromList(flags.aK)
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$modalShowing = _Platform_outgoingPort('modalShowing', $elm$json$Json$Encode$bool);
var $author$project$Modal$FadeOut = function (a) {
	return {$: 2, a: a};
};
var $author$project$Modal$fadeDelay = 200;
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Modal$hide = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{O: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeOut,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay)));
};
var $author$project$Modal$FadeIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$show = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{W: true, O: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeIn,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay / 2)));
};
var $author$project$Scaffold$processEffects = F4(
	function (modal, cmds, config, effs) {
		switch (effs.$) {
			case 4:
				return _Utils_Tuple3(modal, cmds, config);
			case 3:
				var curved = effs.a;
				var inverted = effs.b;
				var content = effs.c;
				var _v1 = $author$project$Modal$show(modal);
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					$elm$core$Maybe$Just(
						{aR: content, aU: curved, aX: inverted}));
			case 2:
				var _v2 = $author$project$Modal$hide(modal);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					config);
			case 0:
				var cmd = effs.b;
				return _Utils_Tuple3(
					modal,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, cmd)
							])),
					config);
			default:
				var childEffs = effs.a;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (eff_, _v3) {
							var modal_ = _v3.a;
							var cmds_ = _v3.b;
							var config_ = _v3.c;
							return A4($author$project$Scaffold$processEffects, modal_, cmds_, config_, eff_);
						}),
					_Utils_Tuple3(modal, cmds, config),
					childEffs);
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Modal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
			case 0:
				var p = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(p)),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{O: true}),
					$elm$core$Platform$Cmd$none,
					model.F(true));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{W: false}),
					$elm$core$Platform$Cmd$none,
					model.F(false));
			default:
				if (!model.y) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
				} else {
					var _v1 = $author$project$Modal$hide(model);
					var m = _v1.a;
					var c = _v1.b;
					return _Utils_Tuple3(m, $elm$core$Platform$Cmd$none, c);
				}
		}
	});
var $author$project$Ports$updateBlurbCache = _Platform_outgoingPort('updateBlurbCache', $elm$core$Basics$identity);
var $author$project$Scaffold$update = F4(
	function (appInit, appUpdate, msg, model) {
		switch (msg.$) {
			case 7:
				var resources = msg.a;
				var viewport = msg.b;
				if (!model.$) {
					var flags = model.a;
					var allBlurbs = _Utils_ap(
						$author$project$Scaffold$extraBlurbs,
						_Utils_ap(resources, flags.dp));
					return _Utils_Tuple2(
						A2(
							$author$project$Scaffold$Uninitialised,
							flags,
							$elm$core$Maybe$Just(viewport)),
						A3(
							$author$project$ScaffoldApi$loadInitialData,
							allBlurbs,
							flags,
							$author$project$Scaffold$CompleteInitialisation(allBlurbs)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var url = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{ct: url}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var encoded = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var translate = external._;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						var _v4 = A2($elm$json$Json$Decode$decodeValue, $author$project$Student$studentDecoder, encoded);
						if (_v4.$ === 1) {
							var err = _v4.a;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, modal, modalContent),
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$decodingErrorMessage,
										translate($author$project$Scaffold$errorTitleBlurb),
										$elm$core$Maybe$Just(
											translate($author$project$Scaffold$decodeStudentErrorBlurb)),
										'Decoding Student',
										err)));
						} else {
							var student = _v4.a;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{X: student}),
									appModel,
									modal,
									modalContent),
								$elm$core$Platform$Cmd$none);
						}
					case 0:
						var flags = model.a;
						var viewport = model.b;
						return _Utils_Tuple2(
							A2($author$project$Scaffold$Uninitialised, flags, viewport),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (msg.b.$ === 1) {
					var err = msg.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Unable to initialise the school'),
								'GetNextGroupClass',
								err)));
				} else {
					var allBlurbs = msg.a;
					var blurbs = msg.b.a.cH;
					var student = msg.b.a.X;
					switch (model.$) {
						case 0:
							var flags = model.a;
							var viewport = model.b;
							var modal = $author$project$Modal$init(
								$author$project$Modal$defaultConfig($author$project$Ports$modalShowing));
							var _v6 = A5($author$project$Scaffold$makeExternalState, flags, blurbs, viewport, allBlurbs, student);
							var external = _v6.a;
							var cmd = _v6.b;
							var _v7 = appInit(external);
							var appModel = _v7.a;
							var appEffects = _v7.b;
							var _v8 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing, appEffects);
							var updatedModal = _v8.a;
							var initCmds = _v8.b;
							var updatedModalConfig = _v8.c;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, updatedModal, updatedModalConfig),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											initCmds,
											cmd,
											$author$project$Ports$updateBlurbCache(
											$author$project$ScaffoldData$encodeBlurbCache(blurbs))
										])));
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 8:
				if (msg.a.$ === 1) {
					var err = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Error retrieving translations'),
								'GetTranslations',
								err)));
				} else {
					var blurbCache = msg.a.a;
					switch (model.$) {
						case 1:
							var external = model.a;
							var appModel = model.b;
							var modal = model.c;
							var modalContent = model.d;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{
											Q: blurbCache,
											_: A2($author$project$ScaffoldData$createTranslationFunc, external.C, blurbCache)
										}),
									appModel,
									modal,
									modalContent),
								$author$project$Ports$updateBlurbCache(
									$author$project$ScaffoldData$encodeBlurbCache(blurbCache)));
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				var lang = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						return _Utils_Tuple2(
							A4(
								$author$project$Scaffold$Initialised,
								_Utils_update(
									external,
									{C: lang}),
								appModel,
								modal,
								modalContent),
							A4($author$project$ScaffoldApi$loadTranslations, external.ac, lang, external.Q, $author$project$Scaffold$ReceivedTranslations));
					case 0:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var appMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalConfig = model.d;
					var _v12 = A3(appUpdate, external, appMsg, appModel);
					var updatedAppModel = _v12.a;
					var appEffects = _v12.b;
					var _v13 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, modalConfig, appEffects);
					var updatedModal = _v13.a;
					var cmds = _v13.b;
					var updatedModalConfig = _v13.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, updatedAppModel, updatedModal, updatedModalConfig),
						cmds);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					var _v15 = A2($author$project$Modal$update, subMsg, modal);
					var updated = _v15.a;
					var parentCmd = _v15.b;
					var subCmds = _v15.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, appModel, updated, modalContent),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, subCmds),
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, parentCmd)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var width = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{
									aE: $author$project$Scaffold$screenSizeFromWidth(width)
								}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Modal$ModalContent = $elm$core$Basics$identity;
var $author$project$Modal$Parent = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Modal$wrap = $elm$html$Html$map($author$project$Modal$Parent);
var $author$project$Modal$content = function (child) {
	return function (_v0) {
		return $author$project$Modal$wrap(child);
	};
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$HtmlUtils$empty = $elm$html$Html$text('');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Icons$CloseIcon$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 30 30')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M25.29 27a1 1 0 01-.7-.29L5.29 7.41a1 1 0 010-1.41L6 5.29a1 1 0 011.41 0l19.3 19.3a1 1 0 010 1.41l-.71.71a1 1 0 01-.71.29z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M6.71 27a1 1 0 01-.71-.29L5.29 26a1 1 0 010-1.41l19.3-19.3a1 1 0 011.41 0l.71.71a1 1 0 010 1.41l-19.3 19.3a1 1 0 01-.7.29z')
				]),
			_List_Nil)
		]));
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $author$project$Modal$modal = F4(
	function (curved, inverted, model, _v0) {
		var renderContent = _v0;
		return model.W ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-modal js-modal'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-visible', model.O)
						])),
					$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-modal__container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-modal__inner-w'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('-curved', curved)
										])),
									A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal'),
									A2(
									$elm$html$Html$Events$stopPropagationOn,
									'click',
									$elm$json$Json$Decode$succeed(
										_Utils_Tuple2($author$project$Modal$NoOp, true)))
								]),
							_List_fromArray(
								[
									model.y ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-modal__close-btn'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('-inverted', inverted)
												])),
											A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal-close'),
											A2($elm$html$Html$Attributes$attribute, 'tabindex', '0'),
											$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
										]),
									_List_fromArray(
										[$author$project$Icons$CloseIcon$icon])) : $author$project$HtmlUtils$empty,
									renderContent(model)
								]))
						]))
				])) : $author$project$HtmlUtils$empty;
	});
var $author$project$Scaffold$view = F3(
	function (appView, loadingView, model) {
		switch (model.$) {
			case 1:
				var external = model.a;
				var appModel = model.b;
				var modal = model.c;
				var modalConfig = model.d;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Scaffold$AppMsg,
							A2(appView, external, appModel)),
							function () {
							if (!modalConfig.$) {
								var curved = modalConfig.a.aU;
								var inverted = modalConfig.a.aX;
								var content = modalConfig.a.aR;
								return A2(
									$elm$html$Html$map,
									$author$project$Scaffold$ModalMsg,
									A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$author$project$Modal$modal,
												curved,
												inverted,
												modal,
												$author$project$Modal$content(
													A2(content, external, appModel)))
											])));
							} else {
								return $author$project$HtmlUtils$empty;
							}
						}()
						]));
			case 0:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
			default:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
		}
	});
var $author$project$Scaffold$bootstrap = F6(
	function (resources, appView, loadingView, appInit, appUpdate, appSubs) {
		return $elm$browser$Browser$element(
			{
				dU: $author$project$Scaffold$init(resources),
				fa: $author$project$Scaffold$subscriptions(appSubs),
				fk: A2($author$project$Scaffold$update, appInit, appUpdate),
				fn: A2($author$project$Scaffold$view, appView, loadingView)
			});
	});
var $author$project$Data$DataReceived = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Data$LoadDataEffect = 0;
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Data$NoModal = {$: 0};
var $author$project$Scaffold$BatchEffect = function (a) {
	return {$: 1, a: a};
};
var $author$project$EffectUtils$batchEffect = $author$project$Scaffold$BatchEffect;
var $author$project$Scaffold$CloseModal = {$: 2};
var $author$project$EffectUtils$closeModal = $author$project$Scaffold$CloseModal;
var $author$project$Scaffold$Effect = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$EffectUtils$fromCmd = $author$project$Scaffold$Effect;
var $author$project$Components$Audio$init = {R: 0, t: false, V: 0};
var $author$project$Api$apiPath = $author$project$ServerPath$serverPath + '/leveltest';
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.ci));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$ApiTypes$LevelTest$Index$LevelTest = F5(
	function (testId, levelName, levelNumber, outline, status) {
		return {d6: levelName, d7: levelNumber, eB: outline, e5: status, ff: testId};
	});
var $author$project$ApiTypes$LevelTest$Index$LevelTestStatusCompleteTest = function (a) {
	return {$: 0, a: a};
};
var $author$project$ApiTypes$LevelTest$Index$LevelTestStatusIncompleteTest = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$LevelTest$Index$CompleteTest = F4(
	function (isPassed, overallScore, sections, certificates) {
		return {cN: certificates, d_: isPassed, eC: overallScore, eY: sections};
	});
var $author$project$ApiTypes$Certificates$Certificate = F6(
	function (key, course, coursetype, date, level, levelCertificate) {
		return {cY: course, c0: coursetype, c7: date, d$: key, d3: level, d4: levelCertificate};
	});
var $author$project$Data$certificateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'levelCertificate', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'level', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'date', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'coursetype', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'course', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$ApiTypes$Certificates$Certificate)))))));
var $author$project$ApiTypes$LevelTest$Index$CompleteSection = F2(
	function (name, score) {
		return {a0: name, cb: score};
	});
var $author$project$Data$completeSectionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'score', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$CompleteSection)));
var $author$project$Data$completeTestDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$field,
		'certificates',
		$elm$json$Json$Decode$list($author$project$Data$certificateDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'sections',
			$elm$json$Json$Decode$list($author$project$Data$completeSectionDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'overallScore', $elm$json$Json$Decode$float),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'isPassed', $elm$json$Json$Decode$bool),
				$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$CompleteTest)))));
var $author$project$ApiTypes$LevelTest$Index$IncompleteSection = F4(
	function (name, questions, id, length) {
		return {bK: id, d1: length, a0: name, eM: questions};
	});
var $author$project$ApiTypes$LevelTest$Index$Question = F6(
	function (id, instructions, text, options, mediaType, mediaPath) {
		return {bK: id, dW: instructions, ei: mediaPath, ej: mediaType, eA: options, cn: text};
	});
var $author$project$ApiTypes$LevelTest$Index$Answer = F3(
	function (id, text, index) {
		return {bK: id, dR: index, cn: text};
	});
var $author$project$Data$answerDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'index', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$Answer))));
var $author$project$ApiTypes$LevelTest$Index$Html = 0;
var $author$project$ApiTypes$LevelTest$Index$Reading = 1;
var $author$project$ApiTypes$LevelTest$Index$Sound = 2;
var $author$project$Data$mediaTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'Html':
				return $elm$json$Json$Decode$succeed(0);
			case 'Reading':
				return $elm$json$Json$Decode$succeed(1);
			case 'Sound':
				return $elm$json$Json$Decode$succeed(2);
			default:
				var err = s;
				return $elm$json$Json$Decode$fail('Unexpected media type of ' + err);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Data$questionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'mediaPath', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'mediaType', $author$project$Data$mediaTypeDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'options',
				$elm$json$Json$Decode$list($author$project$Data$answerDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'instructions', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$Question)))))));
var $author$project$Data$incompleteSectionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'questions',
				$elm$json$Json$Decode$list($author$project$Data$questionDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$IncompleteSection)))));
var $author$project$Data$levelTestStatusDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$LevelTest$Index$LevelTestStatusCompleteTest, $author$project$Data$completeTestDecoder),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$ApiTypes$LevelTest$Index$LevelTestStatusIncompleteTest,
			$elm$json$Json$Decode$list($author$project$Data$incompleteSectionDecoder))
		]));
var $author$project$ApiTypes$LevelTest$Index$SectionSummary = F3(
	function (name, length, complete) {
		return {cU: complete, d1: length, a0: name};
	});
var $author$project$Data$sectionSummaryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'complete', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$float),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$SectionSummary))));
var $author$project$Data$levelTestDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'status', $author$project$Data$levelTestStatusDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'outline',
			$elm$json$Json$Decode$list($author$project$Data$sectionSummaryDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'levelNumber', $elm$json$Json$Decode$float),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'levelName', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'testId', $elm$json$Json$Decode$string),
					$elm$json$Json$Decode$succeed($author$project$ApiTypes$LevelTest$Index$LevelTest))))));
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {b5: reqs, cl: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.cr;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.b5));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.cl)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					cA: r.cA,
					bp: r.bp,
					dj: A2(_Http_mapExpect, func, r.dj),
					dK: r.dK,
					ek: r.ek,
					fg: r.fg,
					cr: r.cr,
					ct: r.ct
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{cA: false, bp: r.bp, dj: r.dj, dK: r.dK, ek: r.ek, fg: r.fg, cr: r.cr, ct: r.ct}));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{bp: r.bp, dj: r.dj, dK: _List_Nil, ek: 'POST', fg: $elm$core$Maybe$Nothing, cr: $elm$core$Maybe$Nothing, ct: r.ct});
};
var $author$project$Api$loadData = F2(
	function (retake, onResult) {
		return $elm$http$Http$post(
			{
				bp: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'retake',
								$elm$json$Json$Encode$bool(retake))
							]))),
				dj: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, onResult),
					$author$project$Data$levelTestDecoder),
				ct: $author$project$Api$apiPath
			});
	});
var $author$project$Control$initInternal = F3(
	function (_v0, autoStart, retake) {
		return _Utils_Tuple2(
			{
				cC: $elm$core$Dict$empty,
				H: $author$project$Components$Audio$init,
				af: false,
				J: $krisajenkins$remotedata$RemoteData$Loading,
				ax: $author$project$Data$NoModal,
				L: 0,
				be: $elm$time$Time$millisToPosix(0),
				ch: false
			},
			$author$project$EffectUtils$batchEffect(
				_List_fromArray(
					[
						A2(
						$author$project$EffectUtils$fromCmd,
						0,
						A2(
							$author$project$Api$loadData,
							retake,
							$author$project$Data$DataReceived(autoStart))),
						$author$project$EffectUtils$closeModal
					])));
	});
var $author$project$Control$init = function (external) {
	return A3($author$project$Control$initInternal, external, false, false);
};
var $author$project$Data$AudioMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Data$LanguageChange = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Audio$Duration = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Audio$Ended = {$: 1};
var $author$project$Components$Audio$Position = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Audio$audioDuration = _Platform_incomingPort('audioDuration', $elm$json$Json$Decode$float);
var $author$project$Components$Audio$audioEnded = _Platform_incomingPort('audioEnded', $elm$json$Json$Decode$string);
var $author$project$Components$Audio$audioPosition = _Platform_incomingPort('audioPosition', $elm$json$Json$Decode$float);
var $author$project$Components$Audio$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Components$Audio$audioEnded(
				function (_v1) {
					return $author$project$Components$Audio$Ended;
				}),
				$author$project$Components$Audio$audioDuration($author$project$Components$Audio$Duration),
				$author$project$Components$Audio$audioPosition($author$project$Components$Audio$Position)
			]));
};
var $author$project$Control$subscriptions = F2(
	function (_v0, model) {
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					$author$project$Ports$onLanguageChange($author$project$Data$LanguageChange),
					A2(
					$elm$core$Platform$Sub$map,
					$author$project$Data$AudioMsg,
					$author$project$Components$Audio$subscriptions(model.H))
				]));
	});
var $author$project$Data$AudioEffect = 3;
var $author$project$Data$BubbleErrorEffect = 1;
var $author$project$Data$CaptureCurrentTime = function (a) {
	return {$: 8, a: a};
};
var $author$project$Data$CaptureTimeEffect = 4;
var $author$project$Data$ChangeUrlEffect = 6;
var $author$project$Data$EndOfSection = {$: 1};
var $author$project$Data$FinalSectionComplete = {$: 2};
var $author$project$Data$ReadingQuestion = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$ReceivedSectionScore = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Data$ShowCertificatesEffect = 7;
var $author$project$Data$StartTest = {$: 1};
var $author$project$Data$StartTestEffect = 2;
var $author$project$Data$SubmitSectionScoreEffect = 5;
var $author$project$Scaffold$NoEffect = {$: 4};
var $author$project$EffectUtils$noEffect = $author$project$Scaffold$NoEffect;
var $author$project$Components$Audio$pauseAudio = _Platform_outgoingPort(
	'pauseAudio',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$Audio$setAudioSource = _Platform_outgoingPort('setAudioSource', $elm$json$Json$Encode$string);
var $author$project$Components$Audio$source = F2(
	function (s, m) {
		return _Utils_Tuple2(
			_Utils_update(
				m,
				{t: false}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Components$Audio$pauseAudio(0),
						$author$project$Components$Audio$setAudioSource(s)
					])));
	});
var $author$project$Control$changeQuestion = F2(
	function (model, maybeQuestion) {
		if (!maybeQuestion.$) {
			var question = maybeQuestion.a;
			var _v1 = question.ej;
			if (_v1 === 2) {
				var _v2 = A2($author$project$Components$Audio$source, question.ei, model.H);
				var audioModel = _v2.a;
				var audioCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{H: audioModel}),
					A2(
						$author$project$EffectUtils$fromCmd,
						3,
						A2($elm$core$Platform$Cmd$map, $author$project$Data$AudioMsg, audioCmd)));
			} else {
				return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
			}
		} else {
			return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
		}
	});
var $author$project$Ports$changeUrl = _Platform_outgoingPort(
	'changeUrl',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'blank',
					$elm$json$Json$Encode$bool($.cF)),
					_Utils_Tuple2(
					'external',
					$elm$json$Json$Encode$bool($.$7)),
					_Utils_Tuple2(
					'url',
					$elm$json$Json$Encode$string($.ct))
				]));
	});
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Data$endOfTest = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map(
		function (test) {
			var _v0 = test.e5;
			if (!_v0.$) {
				return true;
			} else {
				if (!_v0.a.b) {
					return true;
				} else {
					return false;
				}
			}
		}),
	$krisajenkins$remotedata$RemoteData$withDefault(false));
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Data$firstQuestion = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map(
		function (test) {
			var _v0 = test.e5;
			if ((_v0.$ === 1) && _v0.a.b) {
				var _v1 = _v0.a;
				var section = _v1.a;
				return $elm$core$List$head(section.eM);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Data$isFinalSection = function (test) {
	var _v0 = test.e5;
	if (!_v0.$) {
		return true;
	} else {
		var sections = _v0.a;
		return $elm$core$List$length(sections) === 1;
	}
};
var $author$project$Ports$maybeBubbleError = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Ports$bubbleError),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $author$project$Data$CloseModal = {$: 5};
var $author$project$Data$SubmitSectionScore = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Button$button = F2(
	function (props, content) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-button'),
						$elm$html$Html$Attributes$type_('button')
					]),
				A2(
					$elm$core$List$filterMap,
					function (prop) {
						if (!prop.$) {
							var a = prop.a;
							return $elm$core$Maybe$Just(a);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					props)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-button__content')
						]),
					content)
				]));
	});
var $author$project$Button$ButtonProp = function (a) {
	return {$: 0, a: a};
};
var $author$project$Button$buttonProp = A2($elm$core$Basics$composeL, $author$project$Button$ButtonProp, $elm$html$Html$Attributes$class);
var $author$project$Button$dark = $author$project$Button$buttonProp('-dark');
var $author$project$Button$NullProp = {$: 1};
var $author$project$Button$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Button$NullProp;
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Button$disabled = $author$project$Button$boolProp(
	$author$project$Button$ButtonProp(
		$elm$html$Html$Attributes$disabled(true)));
var $author$project$Button$loading = $author$project$Button$boolProp(
	$author$project$Button$buttonProp('-is-loading'));
var $author$project$Button$onClick = function (msg) {
	return $author$project$Button$ButtonProp(
		$elm$html$Html$Events$onClick(msg));
};
var $author$project$Button$secondary = $author$project$Button$buttonProp('-secondary');
var $author$project$View$View$completedSectionModal = F7(
	function (test, section, model, title, body, cancel, submit) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-lt-modal')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-lt-modal__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-lt-modal__body')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(body)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-lt-modal__action')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$secondary,
									$author$project$Button$onClick($author$project$Data$CloseModal)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(cancel)
								])),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$dark,
									$author$project$Button$onClick(
									A2($author$project$Data$SubmitSectionScore, test, section)),
									$author$project$Button$disabled(model.af),
									$author$project$Button$loading(model.af)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(submit)
								]))
						]))
				]));
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$StringUtils$replaceToken = F2(
	function (needle, replaceWith) {
		return A2($elm$core$String$replace, needle, replaceWith);
	});
var $author$project$StringUtils$replaceNamedToken = F2(
	function (name, replaceWith) {
		return A2($author$project$StringUtils$replaceToken, '{{' + (name + '}}'), replaceWith);
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$View$View$modalView = F4(
	function (test, section, _v0, model) {
		var translate = _v0._;
		var _v1 = model.ax;
		switch (_v1.$) {
			case 0:
				return $author$project$HtmlUtils$empty;
			case 1:
				return A7(
					$author$project$View$View$completedSectionModal,
					test,
					section,
					model,
					A3(
						$author$project$StringUtils$replaceNamedToken,
						'currentSectionTrans',
						section.a0,
						translate($author$project$Blurbs$blurbs.aO)),
					translate($author$project$Blurbs$blurbs.a5),
					translate($author$project$Blurbs$blurbs.aM),
					translate($author$project$Blurbs$blurbs.aS));
			case 2:
				return A7(
					$author$project$View$View$completedSectionModal,
					test,
					section,
					model,
					translate($author$project$Blurbs$blurbs.aP),
					translate($author$project$Blurbs$blurbs.aN),
					translate($author$project$Blurbs$blurbs.aM),
					translate($author$project$Blurbs$blurbs.bg));
			default:
				var question = _v1.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-lt-modal -media')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(question.ei)
								]),
							_List_Nil)
						]));
		}
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Data$moveToNextSection = function (incompleteSection) {
	return $krisajenkins$remotedata$RemoteData$map(
		function (test) {
			var _v0 = test.e5;
			if (!_v0.$) {
				return test;
			} else {
				var sections = _v0.a;
				return _Utils_update(
					test,
					{
						eB: A2(
							$elm$core$List$map,
							function (summary) {
								return _Utils_eq(summary.a0, incompleteSection.a0) ? _Utils_update(
									summary,
									{cU: true}) : summary;
							},
							test.eB),
						e5: $author$project$ApiTypes$LevelTest$Index$LevelTestStatusIncompleteTest(
							A2(
								$elm$core$List$filter,
								function (s) {
									return !_Utils_eq(s, incompleteSection);
								},
								sections))
					});
			}
		});
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Ports$openCertificates = _Platform_outgoingPort(
	'openCertificates',
	$elm$json$Json$Encode$list(
		function ($) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'course',
						$elm$json$Json$Encode$string($.cY)),
						_Utils_Tuple2(
						'coursetype',
						$elm$json$Json$Encode$float($.c0)),
						_Utils_Tuple2(
						'date',
						$elm$json$Json$Encode$string($.c7)),
						_Utils_Tuple2(
						'key',
						$elm$json$Json$Encode$string($.d$)),
						_Utils_Tuple2(
						'level',
						$elm$json$Json$Encode$string($.d3)),
						_Utils_Tuple2(
						'levelCertificate',
						$elm$json$Json$Encode$string($.d4))
					]));
		}));
var $author$project$Scaffold$OpenModal = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$EffectUtils$openModal = A2($author$project$Scaffold$OpenModal, false, false);
var $author$project$TaskUtils$performMsg = function (msg) {
	return A2(
		$elm$core$Task$perform,
		function (_v0) {
			return msg;
		},
		$elm$core$Task$succeed(0));
};
var $author$project$DateUtils$posixToFloat = A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$core$Basics$toFloat);
var $author$project$Api$submitSectionAnswers = F5(
	function (test, section, answers, startTime, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A2(
				$elm$core$Task$andThen,
				function (now) {
					var startMs = $author$project$DateUtils$posixToFloat(startTime);
					var nowMs = $author$project$DateUtils$posixToFloat(now);
					var timeSpent = $elm$core$Basics$ceiling((nowMs - startMs) / 60000);
					return $elm$http$Http$task(
						{
							bp: $elm$http$Http$jsonBody(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'levelTestId',
											$elm$json$Json$Encode$string(test.ff)),
											_Utils_Tuple2(
											'sectionId',
											$elm$json$Json$Encode$string(section.bK)),
											_Utils_Tuple2(
											'answers',
											A2(
												$elm$json$Json$Encode$list,
												function (a) {
													return $elm$json$Json$Encode$object(
														_List_fromArray(
															[
																_Utils_Tuple2(
																'id',
																$elm$json$Json$Encode$string(a.bK)),
																_Utils_Tuple2(
																'index',
																$elm$json$Json$Encode$float(a.dR))
															]));
												},
												answers)),
											_Utils_Tuple2(
											'timeSpent',
											$elm$json$Json$Encode$int(timeSpent)),
											_Utils_Tuple2(
											'length',
											$elm$json$Json$Encode$float(section.d1))
										]))),
							dK: _List_Nil,
							ek: 'post',
							eS: $author$project$HttpUtils$whateverResolver,
							fg: $elm$core$Maybe$Nothing,
							ct: $author$project$Api$apiPath + '/submitsectionscore'
						});
				},
				$elm$time$Time$now));
	});
var $author$project$Components$Audio$playAudio = _Platform_outgoingPort(
	'playAudio',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$Audio$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{t: false, V: 0}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var duration = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{R: duration}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var position = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{V: position}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{t: !model.t}),
					model.t ? $author$project$Components$Audio$pauseAudio(0) : $author$project$Components$Audio$playAudio(0));
		}
	});
var $author$project$Messages$webDataErrorMessage = F4(
	function (displayTitle, displayMessage, context, data) {
		if (data.$ === 2) {
			var err = data.a;
			return $elm$core$Maybe$Just(
				A4($author$project$Messages$httpErrorMessage, displayTitle, displayMessage, context, err));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Control$update = F3(
	function (external, msg, model) {
		var paths = external.eD;
		switch (msg.$) {
			case 14:
				var certificates = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						7,
						$author$project$Ports$openCertificates(certificates)));
			case 0:
				if (msg.b.$ === 2) {
					var data = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: data}),
						A2(
							$author$project$EffectUtils$fromCmd,
							1,
							$author$project$Ports$maybeBubbleError(
								A4(
									$author$project$Messages$webDataErrorMessage,
									'Error!',
									$elm$core$Maybe$Just('Something went wrong'),
									'GetLevelTest',
									data))));
				} else {
					var autoStart = msg.a;
					var data = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: data}),
						autoStart ? A2(
							$author$project$EffectUtils$fromCmd,
							2,
							$author$project$TaskUtils$performMsg($author$project$Data$StartTest)) : $author$project$EffectUtils$noEffect);
				}
			case 1:
				var _v1 = A2(
					$author$project$Control$changeQuestion,
					model,
					$author$project$Data$firstQuestion(model.J));
				var updatedModel = _v1.a;
				var eff = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{ch: true}),
					$author$project$EffectUtils$batchEffect(
						_List_fromArray(
							[
								A2(
								$author$project$EffectUtils$fromCmd,
								4,
								A2($elm$core$Task$perform, $author$project$Data$CaptureCurrentTime, $elm$time$Time$now)),
								eff
							])));
			case 8:
				var now = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{be: now}),
					$author$project$EffectUtils$noEffect);
			case 2:
				var section = msg.a;
				var prevIndex = model.L - 1;
				var _v2 = A2(
					$author$project$Control$changeQuestion,
					model,
					A2($elm_community$list_extra$List$Extra$getAt, prevIndex, section.eM));
				var updatedModel = _v2.a;
				var eff = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{L: prevIndex}),
					eff);
			case 3:
				var test = msg.a;
				var section = msg.b;
				var nextIndex = model.L + 1;
				var nextQuestion = A2($elm_community$list_extra$List$Extra$getAt, nextIndex, section.eM);
				if (!nextQuestion.$) {
					var _v4 = A2($author$project$Control$changeQuestion, model, nextQuestion);
					var updatedModel = _v4.a;
					var eff = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{L: nextIndex}),
						eff);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ax: $author$project$Data$isFinalSection(test) ? $author$project$Data$FinalSectionComplete : $author$project$Data$EndOfSection
							}),
						$author$project$EffectUtils$openModal(
							A2($author$project$View$View$modalView, test, section)));
				}
			case 4:
				var index = msg.a;
				var answer = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cC: A3($elm$core$Dict$insert, index, answer, model.cC)
						}),
					$author$project$EffectUtils$noEffect);
			case 11:
				var test = msg.a;
				var section = msg.b;
				var question = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ax: $author$project$Data$ReadingQuestion(question)
						}),
					$author$project$EffectUtils$openModal(
						A2($author$project$View$View$modalView, test, section)));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ax: $author$project$Data$NoModal}),
					$author$project$EffectUtils$closeModal);
			case 6:
				var test = msg.a;
				var section = msg.b;
				var answers = $elm$core$Dict$values(model.cC);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: true}),
					A2(
						$author$project$EffectUtils$fromCmd,
						5,
						A5(
							$author$project$Api$submitSectionAnswers,
							test,
							section,
							answers,
							model.be,
							$author$project$Data$ReceivedSectionScore(section))));
			case 7:
				if (!msg.b.$) {
					var incompleteSection = msg.a;
					var data = A2($author$project$Data$moveToNextSection, incompleteSection, model.J);
					if ($author$project$Data$endOfTest(data)) {
						return A3($author$project$Control$initInternal, external, false, false);
					} else {
						var _v5 = A2(
							$author$project$Control$changeQuestion,
							model,
							$author$project$Data$firstQuestion(data));
						var updatedModel = _v5.a;
						var eff = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								updatedModel,
								{cC: $elm$core$Dict$empty, af: false, J: data, L: 0}),
							$author$project$EffectUtils$batchEffect(
								_List_fromArray(
									[
										$author$project$EffectUtils$closeModal,
										A2(
										$author$project$EffectUtils$fromCmd,
										4,
										A2($elm$core$Task$perform, $author$project$Data$CaptureCurrentTime, $elm$time$Time$now)),
										eff
									])));
					}
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{af: false}),
						A2(
							$author$project$EffectUtils$fromCmd,
							1,
							$author$project$Ports$bubbleError(
								A4($author$project$Messages$httpErrorMessage, 'Error', $elm$core$Maybe$Nothing, 'ReceivedSectionScore', err))));
				}
			case 9:
				return A3($author$project$Control$initInternal, external, false, true);
			case 10:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						6,
						$author$project$Ports$changeUrl(paths.dO)));
			case 12:
				return A3($author$project$Control$initInternal, external, true, false);
			default:
				var subMsg = msg.a;
				var _v6 = A2($author$project$Components$Audio$update, subMsg, model.H);
				var subModel = _v6.a;
				var subCmd = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{H: subModel}),
					A2(
						$author$project$EffectUtils$fromCmd,
						3,
						A2($elm$core$Platform$Cmd$map, $author$project$Data$AudioMsg, subCmd)));
		}
	});
var $author$project$Data$AnswerQuestion = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Data$NextQuestion = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Data$PreviousQuestion = function (a) {
	return {$: 2, a: a};
};
var $author$project$Data$ShowReadingMaterial = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Components$Audio$TogglePlaying = {$: 0};
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$CssUtils$qa = $elm$html$Html$Attributes$attribute('data-qa');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Components$Audio$audio = function (model) {
	var pad = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		A2($elm$core$String$padLeft, 2, '0'));
	var fillRatio = model.t ? (model.V / model.R) : 0;
	var gradient = (360 * fillRatio) + 90;
	var fillColor = '#009EE8';
	var emptyColor = '#e5e5e5';
	var durationInt = $elm$core$Basics$floor(model.R);
	var mins = (durationInt / 60) | 0;
	var secs = durationInt - (mins * 60);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-audio')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-audio__circle-w')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-audio__circle'),
								(fillRatio <= 0.5) ? A2(
								$elm$html$Html$Attributes$style,
								'background-image',
								'linear-gradient(270deg, transparent 50%, ' + (emptyColor + (' 50%), linear-gradient(' + ($elm$core$String$fromFloat(gradient) + ('deg, transparent 50%, ' + (emptyColor + ' 50%)')))))) : A2(
								$elm$html$Html$Attributes$style,
								'background-image',
								'linear-gradient(270deg, ' + (fillColor + (' 50%, transparent 50%), linear-gradient(' + ($elm$core$String$fromFloat(gradient) + ('deg, transparent 50%, ' + (emptyColor + ' 50%)'))))))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-audio__circle-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('c-audio__btn'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('-stop', model.t),
												_Utils_Tuple2('-play', !model.t)
											])),
										$elm$html$Html$Events$onClick($author$project$Components$Audio$TogglePlaying),
										$author$project$CssUtils$qa('audio-player')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-audio__duration')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						pad(mins) + (':' + pad(secs)))
					]))
			]));
};
var $author$project$Forms$Radio$InputProp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$Radio$NullProp = {$: 2};
var $author$project$Forms$Radio$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Forms$Radio$NullProp;
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Forms$Radio$checked = $author$project$Forms$Radio$boolProp(
	$author$project$Forms$Radio$InputProp(
		$elm$html$Html$Attributes$checked(true)));
var $author$project$Forms$Radio$LabelProp = function (a) {
	return {$: 0, a: a};
};
var $author$project$Forms$Radio$class = A2($elm$core$Basics$composeL, $author$project$Forms$Radio$LabelProp, $elm$html$Html$Attributes$class);
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$Forms$Radio$groupName = A2($elm$core$Basics$composeL, $author$project$Forms$Radio$InputProp, $elm$html$Html$Attributes$name);
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Icons$EBook$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class(cls),
				$elm$svg$Svg$Attributes$height('86'),
				$elm$svg$Svg$Attributes$width('74')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_fromArray(
					[
						A3(
						$elm$svg$Svg$node,
						'circle',
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$cx('37'),
								$elm$svg$Svg$Attributes$cy('37'),
								$elm$svg$Svg$Attributes$fill('#FFF'),
								$elm$svg$Svg$Attributes$r('37')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$stroke('#484F59'),
								$elm$svg$Svg$Attributes$strokeWidth('1.5')
							]),
						_List_fromArray(
							[
								A2(
								$elm$svg$Svg$path,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$d('M57.085 22H60v32H15V22h2.947')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$path,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$d('M34.727 20H18v30.761h16.727C36.535 50.761 38 52.211 38 54V23.239C38 21.45 36.535 20 34.727 20zM38 23.239C38 21.45 39.393 20 41.11 20H57v30.762H41.11c-1.717 0-3.11 1.45-3.11 3.238M21 25h8m-8 4h13m-13 3h13m-13 3h13m-13 4h13m-13 3h13m-13 3h13m7-16h13m-13 3h13m-13 3h13m-13 4h13m-13 3h13m-13 3h13')
									]),
								_List_Nil)
							])),
						A3(
						$elm$svg$Svg$node,
						'circle',
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$cx('37.5'),
								$elm$svg$Svg$Attributes$cy('74.5'),
								$elm$svg$Svg$Attributes$fill('#1EC756'),
								$elm$svg$Svg$Attributes$r('10.5'),
								$elm$svg$Svg$Attributes$stroke('#EFF2F3'),
								$elm$svg$Svg$Attributes$strokeWidth('2')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M32.5 74.5h10m-5-5v10'),
								$elm$svg$Svg$Attributes$stroke('#FFF'),
								$elm$svg$Svg$Attributes$strokeLinecap('round')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Forms$Radio$inverted = $author$project$Forms$Radio$LabelProp(
	$elm$html$Html$Attributes$class('-inverted'));
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Forms$Radio$onClick = A2($elm$core$Basics$composeL, $author$project$Forms$Radio$InputProp, $elm$html$Html$Events$onClick);
var $author$project$Button$primary = $author$project$Button$buttonProp('-primary');
var $author$project$Button$qa = A2($elm$core$Basics$composeR, $author$project$CssUtils$qa, $author$project$Button$ButtonProp);
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Forms$Radio$partition = A2(
	$elm$core$List$foldr,
	F2(
		function (p, _v0) {
			var lps = _v0.a;
			var ips = _v0.b;
			switch (p.$) {
				case 0:
					var a = p.a;
					return _Utils_Tuple2(
						A2($elm$core$List$cons, a, lps),
						ips);
				case 1:
					var a = p.a;
					return _Utils_Tuple2(
						lps,
						A2($elm$core$List$cons, a, ips));
				default:
					return _Utils_Tuple2(lps, ips);
			}
		}),
	_Utils_Tuple2(_List_Nil, _List_Nil));
var $author$project$Forms$Radio$radio = F2(
	function (props, content) {
		var _v0 = $author$project$Forms$Radio$partition(props);
		var labelProps = _v0.a;
		var inputProps = _v0.b;
		return A2(
			$elm$html$Html$label,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-input-w c-boolean')
					]),
				labelProps),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$class('c-boolean__input')
							]),
						inputProps),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-boolean__element -radio')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-boolean__label')
						]),
					content)
				]));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$View$ShowSection$view = F4(
	function (_v0, model, test, section) {
		var translate = _v0._;
		var maybeQuestion = A2($elm_community$list_extra$List$Extra$getAt, model.L, section.eM);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-main')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-question-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-question-header__title'),
									$author$project$CssUtils$qa('test-name')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(test.d6)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-question-header__items')
								]),
							A2(
								$elm$core$List$map,
								function (s) {
									var isCurrent = _Utils_eq(s.a0, section.a0);
									return A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('c-question-header__item'),
												$author$project$CssUtils$qa('section-name'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('is-current', isCurrent),
														_Utils_Tuple2('is-completed', s.cU)
													]))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(s.a0)
											]));
								},
								test.eB))
						])),
					function () {
					if (maybeQuestion.$ === 1) {
						return $author$project$HtmlUtils$empty;
					} else {
						var question = maybeQuestion.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-questions c-questionnaire')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-questions__instructions')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-questions__instructions-text'),
													$author$project$CssUtils$qa('question-instructions')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(question.dW)
												])),
											function () {
											var _v2 = question.ej;
											switch (_v2) {
												case 0:
													return $author$project$HtmlUtils$empty;
												case 2:
													return A2(
														$elm$html$Html$map,
														$author$project$Data$AudioMsg,
														$author$project$Components$Audio$audio(model.H));
												default:
													return A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('c-questions__instructions-media')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$a,
																_List_fromArray(
																	[
																		$elm$html$Html$Events$onClick(
																		A3($author$project$Data$ShowReadingMaterial, test, section, question)),
																		$elm$html$Html$Attributes$class('c-link'),
																		$author$project$CssUtils$qa('reading-modal')
																	]),
																_List_fromArray(
																	[
																		$author$project$Icons$EBook$icon('c-questions__instructions-image'),
																		$elm$html$Html$text(
																		translate($author$project$Blurbs$blurbs.a9))
																	]))
															]));
											}
										}()
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' c-questions__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-questions__question'),
													$author$project$CssUtils$qa('question-question')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(question.cn)
												])),
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-questions__answers c-form-group')
												]),
											A2(
												$elm$core$List$map,
												function (option) {
													var checked = _Utils_eq(
														A2($elm$core$Dict$get, model.L, model.cC),
														$elm$core$Maybe$Just(option));
													var cls = checked ? 'c-questions__answer is-selected' : 'c-questions__answer';
													return A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$author$project$CssUtils$qa('question-answers'),
																$elm$html$Html$Attributes$classList(
																_List_fromArray(
																	[
																		_Utils_Tuple2('c-questions__is-selected', checked)
																	]))
															]),
														_List_fromArray(
															[
																A2(
																$author$project$Forms$Radio$radio,
																_List_fromArray(
																	[
																		$author$project$Forms$Radio$groupName('question-answer'),
																		$author$project$Forms$Radio$checked(checked),
																		$author$project$Forms$Radio$inverted,
																		$author$project$Forms$Radio$onClick(
																		A2($author$project$Data$AnswerQuestion, model.L, option)),
																		$author$project$Forms$Radio$class(cls)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(option.cn)
																	]))
															]));
												},
												question.eA))
										]))
								]));
					}
				}(),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-question-footer')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$secondary,
									$author$project$Button$disabled(model.L <= 0),
									$author$project$Button$onClick(
									$author$project$Data$PreviousQuestion(section)),
									$author$project$Button$qa('prev_btn')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Blurbs$blurbs.a6))
								])),
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.eM) + (' ' + ($elm$core$String$fromInt(model.L + 1) + ('/' + $elm$core$String$fromFloat(section.d1))))),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$primary,
									$author$project$Button$onClick(
									A2($author$project$Data$NextQuestion, test, section)),
									$author$project$Button$qa('next_btn')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Blurbs$blurbs.a2))
								]))
						]))
				]));
	});
var $author$project$HtmlUtils$when = F2(
	function (fn, a) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$HtmlUtils$empty,
			A2($elm$core$Maybe$map, fn, a));
	});
var $author$project$View$View$currentSection = F4(
	function (external, model, test, sections) {
		return A2(
			$author$project$HtmlUtils$when,
			A3($author$project$View$ShowSection$view, external, model, test),
			$elm$core$List$head(sections));
	});
var $author$project$Data$Continue = {$: 10};
var $author$project$ProgressBar$LargeProgressBar = 0;
var $author$project$ProgressBar$MediumProgressBar = 1;
var $author$project$Data$Retake = {$: 9};
var $author$project$Data$ShowCertificates = function (a) {
	return {$: 14, a: a};
};
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$Icons$TestCertificate$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class(cls),
				$elm$svg$Svg$Attributes$height('28'),
				$elm$svg$Svg$Attributes$width('23')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('#009EE8')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M16.936 6.94h5.428a.574.574 0 00.42-.966L17.356.184a.579.579 0 00-.633-.144.58.58 0 00-.362.535v5.79c0 .317.259.575.575.575zM5.597 13.179a7.05 7.05 0 00-1.207 0c-2.467.299-4.33 2.41-4.33 4.905a4.95 4.95 0 002.84 4.462 4.95 4.95 0 001.49.437c.2.023.396.034.603.034H5c.207 0 .403-.011.604-.034a4.985 4.985 0 001.49-.437 4.95 4.95 0 002.84-4.462 4.94 4.94 0 00-4.336-4.905z')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M22.364 8.09h-5.428a1.727 1.727 0 01-1.725-1.725V.575c0-.2.038-.393.103-.575H4.459a.577.577 0 00-.575.575V12.1c.122-.023.243-.047.368-.063.242-.03.57-.034.744-.034.174 0 .502.005.744.035 3.044.368 5.342 2.967 5.342 6.046 0 1.953-.963 3.768-2.512 4.9v1.47h13.794a.577.577 0 00.575-.574V7.987a1.712 1.712 0 01-.575.103zM4.999 24.167h-.006c-.247 0-.488-.013-.734-.042a6.147 6.147 0 01-1.686-.47v3.37a.571.571 0 00.575.575c.15 0 .293-.058.402-.167l1.443-1.443 1.444 1.443a.576.576 0 00.626.121.568.568 0 00.357-.529v-3.37a6.13 6.13 0 01-1.666.468c-.267.03-.507.044-.755.044z')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Icons$TestFail$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class(cls),
				$elm$svg$Svg$Attributes$height('38'),
				$elm$svg$Svg$Attributes$width('37')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M1.6 0h33.8A1.6 1.6 0 0137 1.6v35.182a.8.8 0 01-1.118.735L18.5 30 1.118 37.517A.8.8 0 010 36.782V1.6A1.6 1.6 0 011.6 0z'),
								$elm$svg$Svg$Attributes$fill('#D1334A')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M19.801 16.17l3.256-3.272a.929.929 0 000-1.308.918.918 0 00-1.302 0L18.5 14.862l-3.255-3.272a.918.918 0 00-1.302 0 .929.929 0 000 1.308l3.255 3.272-3.254 3.272a.929.929 0 000 1.308.917.917 0 001.302 0l3.254-3.27 3.254 3.271a.917.917 0 001.302 0 .929.929 0 000-1.308L19.8 16.17'),
								$elm$svg$Svg$Attributes$fill('#FFF')
							]),
						_List_Nil)
					]))
			]));
};
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $author$project$Icons$TestPass$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class(cls),
				$elm$svg$Svg$Attributes$height('38'),
				$elm$svg$Svg$Attributes$width('37')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M1.6 0h33.8A1.6 1.6 0 0137 1.6v35.182a.8.8 0 01-1.118.735L18.5 30 1.118 37.517A.8.8 0 010 36.782V1.6A1.6 1.6 0 011.6 0z'),
								$elm$svg$Svg$Attributes$fill('#5DA335')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M13.674 15.758l3.759 3.646 7.502-7.276'),
								$elm$svg$Svg$Attributes$stroke('#FFF'),
								$elm$svg$Svg$Attributes$strokeLinecap('round'),
								$elm$svg$Svg$Attributes$strokeLinejoin('round'),
								$elm$svg$Svg$Attributes$strokeWidth('1.5')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$ProgressBar$MiniProgressBar = 2;
var $author$project$ListUtils$appendIf = F3(
	function (pred, x, xs) {
		return pred ? _Utils_ap(
			xs,
			_List_fromArray(
				[x])) : xs;
	});
var $author$project$ProgressBar$attributesByPropType = function (fn) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				return A2(fn, p, attrs);
			}),
		_List_Nil);
};
var $author$project$ProgressBar$barProp = F2(
	function (prop, attrs) {
		if (!prop.$) {
			var attr = prop.a;
			return A2($elm$core$List$cons, attr, attrs);
		} else {
			return attrs;
		}
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$ProgressBar$meterProp = F2(
	function (prop, attrs) {
		if (prop.$ === 1) {
			var attr = prop.a;
			return A2($elm$core$List$cons, attr, attrs);
		} else {
			return attrs;
		}
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$ProgressBar$progressBar = F3(
	function (props, mode, percentComplete) {
		var percentClamped = A3($elm$core$Basics$clamp, 0, 100, percentComplete);
		var widthCss = (percentComplete > 0) ? ('calc(' + ($elm$core$String$fromFloat(percentClamped) + '% + 2px)')) : ($elm$core$String$fromFloat(percentClamped) + '%');
		var widthStr = $elm$core$String$fromInt(
			$elm$core$Basics$round(percentClamped)) + '%';
		var invert = percentComplete >= 70;
		var _v0 = function () {
			switch (mode) {
				case 0:
					return _Utils_Tuple2(
						'c-progress-bar',
						$elm$core$Maybe$Just('c-progress-bar__value'));
				case 1:
					return _Utils_Tuple2('c-progress-bar c-progress-bar--medium', $elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple2('c-progress-bar c-progress-bar--mini', $elm$core$Maybe$Nothing);
			}
		}();
		var barCls = _v0.a;
		var valueCls = _v0.b;
		var valueNode = A2(
			$elm$core$Maybe$withDefault,
			$author$project$HtmlUtils$empty,
			A2(
				$elm$core$Maybe$map,
				function (cls) {
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(cls),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('-inverted', invert)
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(widthStr)
							]));
				},
				valueCls));
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				A2($author$project$ProgressBar$attributesByPropType, $author$project$ProgressBar$barProp, props),
				A3(
					$author$project$ListUtils$appendIf,
					(mode === 2) || (mode === 1),
					$elm$html$Html$Attributes$title(widthStr),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(barCls),
							$author$project$CssUtils$qa('progress_bar')
						]))),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-progress-bar__meter'),
								A2($elm$html$Html$Attributes$style, 'width', widthCss),
								$author$project$CssUtils$qa('progress_bar_meter')
							]),
						A2($author$project$ProgressBar$attributesByPropType, $author$project$ProgressBar$meterProp, props)),
					_List_fromArray(
						[
							invert ? valueNode : $author$project$HtmlUtils$empty
						])),
					invert ? $author$project$HtmlUtils$empty : valueNode
				]));
	});
var $author$project$StringUtils$replaceAllNamedTokens = $elm$core$List$foldl(
	F2(
		function (_v0, rest) {
			var name = _v0.a;
			var val = _v0.b;
			return A3($author$project$StringUtils$replaceNamedToken, name, val, rest);
		}));
var $author$project$View$CompleteTest$view = F3(
	function (_v0, completeTest, test) {
		var translate = _v0._;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-main')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-layout__summary c-summary')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-summary__chart')
								]),
							_Utils_ap(
								A2(
									$elm$core$List$map,
									function (section) {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-summary__el'),
													$author$project$CssUtils$qa('section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-summary__el-title-w'),
															$author$project$CssUtils$qa('section-title')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h5,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(section.a0)
																])),
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$elm$core$String$fromFloat(section.cb) + '%')
																]))
														])),
													A3($author$project$ProgressBar$progressBar, _List_Nil, 1, section.cb)
												]));
									},
									completeTest.eY),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('c-summary__el-overall'),
												$author$project$CssUtils$qa('overall')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('c-summary__el-title-w')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h5,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																translate($author$project$Blurbs$blurbs.eC))
															])),
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																$elm$core$String$fromFloat(completeTest.eC) + '%')
															]))
													])),
												A3($author$project$ProgressBar$progressBar, _List_Nil, 0, completeTest.eC)
											]))
									]))),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-summary__description')
								]),
							_List_fromArray(
								[
									completeTest.d_ ? $author$project$Icons$TestPass$icon('c-summary__icon-w') : $author$project$Icons$TestFail$icon('c-summary__icon-w'),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-summary__title'),
											$author$project$CssUtils$qa('completed-message')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											completeTest.d_ ? translate($author$project$Blurbs$blurbs.aQ) : translate($author$project$Blurbs$blurbs.ba))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-summary__copy'),
											$author$project$CssUtils$qa('completed-summary')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											completeTest.d_ ? A2(
												$author$project$StringUtils$replaceAllNamedTokens,
												translate($author$project$Blurbs$blurbs.a4),
												_List_fromArray(
													[
														_Utils_Tuple2(
														'levelNumber',
														$elm$core$String$fromFloat(test.d7)),
														_Utils_Tuple2(
														'overallScore',
														$elm$core$String$fromFloat(completeTest.eC))
													])) : A2(
												$author$project$StringUtils$replaceAllNamedTokens,
												translate($author$project$Blurbs$blurbs.aW),
												_List_fromArray(
													[
														_Utils_Tuple2(
														'levelNumber',
														$elm$core$String$fromFloat(test.d7)),
														_Utils_Tuple2(
														'overallScore',
														$elm$core$String$fromFloat(completeTest.eC))
													])))
										])),
									completeTest.d_ ? A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-summary__link')
										]),
									_List_fromArray(
										[
											$author$project$Icons$TestCertificate$icon('c-summary__icon-l'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-link'),
													$elm$html$Html$Events$onClick(
													$author$project$Data$ShowCertificates(completeTest.cN))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate($author$project$Blurbs$blurbs.bk))
												]))
										])) : $author$project$HtmlUtils$empty
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-layout__footer')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									completeTest.d_ ? $author$project$Button$secondary : $author$project$Button$primary,
									$author$project$Button$onClick($author$project$Data$Retake)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Blurbs$blurbs.eU))
								])),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									completeTest.d_ ? $author$project$Button$primary : $author$project$Button$secondary,
									$author$project$Button$onClick($author$project$Data$Continue)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Blurbs$blurbs.aT))
								]))
						]))
				]));
	});
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$View$Welcome$showSections = F2(
	function (_v0, sections) {
		var translate = _v0._;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-welcome__sections'),
					$author$project$CssUtils$qa('welcome_sections')
				]),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var name = _v1.a0;
					var length = _v1.d1;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-welcome__section'),
								$author$project$CssUtils$qa('welcome_section')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('c-welcome__section-name')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(name)
									])),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('c-welcome__section-length'),
										$author$project$CssUtils$qa('welcome_section_length')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromFloat(length) + (' ' + translate($author$project$Blurbs$blurbs.eM)))
									]))
							]));
				},
				sections));
	});
var $author$project$View$Welcome$view = F2(
	function (external, _v0) {
		var translate = external._;
		var outline = _v0.eB;
		var levelName = _v0.d6;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-welcome c-main')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-welcome__title'),
							$author$project$CssUtils$qa('welcome_title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A3(
								$author$project$StringUtils$replaceNamedToken,
								'levelName',
								levelName,
								translate($author$project$Blurbs$blurbs.bl)))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-welcome__para'),
							$author$project$CssUtils$qa('welcome_para_one')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bj))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-welcome__para'),
							$author$project$CssUtils$qa('welcome_para_two')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bi))
						])),
					A2($author$project$View$Welcome$showSections, external, outline),
					A2(
					$author$project$Button$button,
					_List_fromArray(
						[
							$author$project$Button$primary,
							$author$project$Button$onClick($author$project$Data$StartTest),
							$author$project$Button$qa('welcome_start_btn')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bd))
						]))
				]));
	});
var $author$project$View$View$view = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('l-container')
				]),
			_List_fromArray(
				[
					function () {
					var _v0 = model.J;
					if (_v0.$ === 3) {
						var test = _v0.a;
						var _v1 = test.e5;
						if (!_v1.$) {
							var completeTest = _v1.a;
							return A3($author$project$View$CompleteTest$view, external, completeTest, test);
						} else {
							var incompleteSections = _v1.a;
							return model.ch ? A4($author$project$View$View$currentSection, external, model, test, incompleteSections) : A2($author$project$View$Welcome$view, external, test);
						}
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-main c-welcome -is-loading')
								]),
							_List_Nil);
					}
				}()
				]));
	});
var $author$project$Main$main = A6($author$project$Scaffold$bootstrap, $author$project$Blurbs$allBlurbs, $author$project$View$View$view, $author$project$HtmlUtils$empty, $author$project$Control$init, $author$project$Control$update, $author$project$Control$subscriptions);
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));