import { Elm } from './src/Main.elm';
import './scss/styles.scss';
import { initialise } from '../../common/Elm/bootstrapElm';

const { bootstrap, mount, unmount } = initialise([{
    elmModule: Elm.Main,
    domGetter: () => document.getElementById('app-leveltest'),
    flags: { },
    configurePorts: (ports) => {
        const audio = new Audio();
        audio.addEventListener('ended', () => {
            ports.audioEnded.send('ended');
        });

        audio.addEventListener('error', (sound, err) => {
            log('audio error: %s', err);
        });

        audio.addEventListener('canplaythrough', () => {
            ports.audioDuration.send(audio.duration);
        });

        audio.addEventListener('timeupdate', () => {
            ports.audioPosition.send(audio.currentTime);
        });

        ports.setAudioSource.subscribe((src) => {
            audio.src = src;
        });

        ports.playAudio.subscribe(() => {
            audio.play();
        });

        ports.pauseAudio.subscribe(() => {
            audio.pause();
            audio.currentTime = 0;
        });
    },
}]);

export {
    bootstrap,
    mount,
    unmount,
};
